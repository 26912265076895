<template>
  <main>
    <div class="pb">
      <Button icon="md-return-left" shape="circle" @click="$router.back()">返回</Button>
    </div>

    <Form ref="form" :model="form" :rules="validate" label-position="top">
      <Divider orientation="left">基本信息</Divider>
      <Row>
        <Col :span="9" :offset="3">
        <FormItem label="分类" prop="video_category_id">
          <Select v-model="form.video_category_id" class="w50" label-in-value @on-select="onSelectCategory">
            <Option :value="item.id" v-for="item in categorys" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>

        <FormItem label="标题" prop="title">
          <Input :maxlength="45" show-word-limit v-model="form.title" @on-change="onInputChangeTitle" />
        </FormItem>
        <FormItem label="封面(非必需)" prop="cover_url">
          <upload-image class="w50-max" name="video-cover" v-model="form.cover_url"
            @on-upload-success="onUploadImageSuccess" />
        </FormItem>
        <FormItem label="视频">
          <upload-ali-vod class="w50-max" name="video" :params="uploadParams"
            v-model="form.video_url" @on-upload-success="onUploadVodSuccess" />
        </FormItem>
        <FormItem label="时长" prop="duration">
          <Input :maxlength="45" show-word-limit v-model="form.duration" />
        </FormItem>
        <FormItem label="详情" prop="description">
          <Input type="textarea" placeholder="请输入视频详情" style="width: 600px" :rows="5" v-model="form.description" />
        </FormItem>
        <FormItem label="权重排序">
          <InputNumber :min="-32768" :max="32767" class="input-number-long" v-model="form.weight" />
          <p class="text-sm text-grey">从大到小排序</p>
        </FormItem>

        <FormItem label="是否可见">
          <i-switch v-model="form.status" size="large" :true-value="1" :false-value="0">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-switch>
        </FormItem>
        </Col>
      </Row>

      <Divider />
      <Row>
        <Col :span="9" :offset="3">
        <FormItem>
          <Button type="primary" icon="md-add-circle" @click="submit" v-if="!id">确认添加</Button>
          <Button type="success" icon="md-filing" @click="submit" v-else>保存修改</Button>
        </FormItem>
        </Col>
      </Row>
    </Form>
  </main>
</template>

<script>
import UploadImage from "@/components/UploadImage";
import UploadAliVod from "@/components/UploadAliVod";

export default {
  components: {
    UploadImage,
    UploadAliVod
  },
  data() {
    return {
      id: this.$route.params.id,
      uploadParams: {
        title: "",
        cover_url: "",
        tags: ''
      },
      form: {
        video_category_id: 0,
        video_id: "",
        title: "",
        cover_url: "",
        description: "",
        duration: '',
        weight: 0,
        status: 1,
        video_url: ''
      },
      validate: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        video_id: [{ required: true, message: "请上传视频", trigger: "blur" }],
        video_category_id: [
          {
            required: true,
            type: "number",
            min: 0,
            message: "请选择视频分类",
            trigger: "blur"
          }
        ]
      },
      categorys: [],

    };
  },
  created() {
    this.loadCategorys();
  },
  methods: {
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const url =
            "/video/" + (this.id ? "update/id/" + this.id : "create");
          this.$http.post(url, this.form).then(res => {
            this.$router.back();
          });
        }
      });
    },
    loadData() {
      this.$http.get("/video/detail/id/" + this.id).then(res => {
        this.form = res;
      });
    },
    loadCategorys() {
      this.$http.get("/videoCategory/list").then(res => {
        this.categorys = res.data;
        this.id && this.loadData();
      });
    },
    onSelectCategory(e) {

      this.uploadParams.tags = e.label
    },
    onInputChangeTitle(e) {

      this.uploadParams.title = e.target.value
    },
    onUploadImageSuccess(res) {

      this.uploadParams.cover_url = res
    },
   
    onUploadVodSuccess(data) {

      this.form.video_id = data;
      let params = {
        video_id: data,
      };
      this.$http.get("/video/getPlayInfo", { params: params }).then(res => {
        this.form.duration = this.getTime(res.playInfoList.playInfo[0].duration);
        this.form.cover_url = this.form.cover_url ? this.form.cover_url : res.videoBase.coverURL
        this.form.video_url = res.playInfoList.playInfo[0].playURL

      });

    },
    getTime(second) {
      // 转换为式分秒
      let h = parseInt(second / 60 / 60 % 24)
      h = h < 10 ? '0' + h : h
      let m = parseInt(second / 60 % 60)
      m = m < 10 ? '0' + m : m
      let s = parseInt(second % 60)
      s = s < 10 ? '0' + s : s
      // 作为返回值返回
      return h + ':' + m + ':' + s
    },
    
  }
};
</script>