<template>
    <section>
        <Upload type="drag" :name="name" :data="params" :multiple="false" :show-upload-list="false" :format="format"
            :max-size="maxSize" :action="action" :headers="headers" :before-upload="beforeUpload" :on-error="error"
            :on-format-error="formatError" :on-exceeded-size="sizeError">
            <div class="pd-xs radius">
                <div class="pd-tb" v-if="loading">
                    <Spin>
                        <Icon type="ios-loading" size="36" class="spin-icon-load"></Icon>
                        <p>{{ statusText }}</p>
                    </Spin>
                </div>
                <div v-else>
                    <div class="pos-rel" v-if="preview">
                        <Icon type="md-close-circle" class="trash-video" size="16" @click.stop="delVideo"></Icon>
                        <video class="responsive-img" :src="preview" controls />
                    </div>
                    <template v-else>
                        <Icon type="md-cloud-upload" size="26" class="text-primary" />
                        <p class="text-blue-grey" v-if="text">{{ text }}</p>
                    </template>
                </div>
            </div>
        </Upload>
    </section>
</template>

<script>
import OSS from '../../lib/aliyun-upload-sdk/lib/aliyun-oss-sdk-6.17.1.min'
window.OSS = OSS;
import '../../lib/aliyun-upload-sdk/aliyun-upload-sdk-1.5.6.min'
export default {
    props: {
        value: {
            type: String,
            required: true
        },
        name: {
            type: String,
            default: 'video'
        },
        params: {
            type: Object
        },

        maxSize: {
            type: Number,
            default: 0
        },
        format: {
            type: Array,
            default() {
                return ['mp4']
            }
        },
        text: {
            type: String,
            default: '上传视频'
        }
    },
    data() {
        return {
            currentValue: this.value,
            preview: false,
            action: '',
            headers: {},
            loading: false,
            statusText: '上传中'
        }
    },
    created() {

    },
    mounted() {
        this.showPreview()
    },
    methods: {
        beforeUpload(file) {
            this.loading = true
            this.params.file_name = file.name
            var paramData = {
                Vod: {
                    Title: this.params.title,
                    CoverURL: this.params.cover_url,
                    Tags: this.params.tags
                }
            }
            var userData = JSON.stringify(paramData);
            if (this.uploader) {
                this.uploader.stopUpload()
                this.statusText = ""
            }
            this.uploader = this.createUploader()
            
            this.uploader.addFile(file, null, null, null, userData)

            return false;
        },
        authUpload() {
            // 然后调用 startUpload 方法, 开始上传
            if (this.uploader !== null) {
                this.uploader.startUpload()
            }
        },
        // 暂停上传
        pauseUpload() {
            if (this.uploader !== null) {
                this.uploader.stopUpload()
            }
        },
        // 恢复上传
        resumeUpload() {
            if (this.uploader !== null) {
                this.uploader.startUpload()
            }
        },
        createUploader() {
            let self = this
            let uploader = new AliyunUpload.Vod({
                timeout: 90000,
                partSize: 1048576,
                parallel: 5,
                retryCount: 3,
                retryDuration: 2,
                region: 'cn-shanghai',
                userId: '666',
                localCheckpoint: true, //此参数是禁用服务端缓存，不影响断点续传
                // 添加文件成功
                addFileSuccess: function (uploadInfo) {
                    self.statusText = '添加文件成功, 等待上传...'
                    // console.log("addFileSuccess: " + uploadInfo.file.name)
                    self.authUpload()
                },
                // 开始上传
                onUploadstarted: function (uploadInfo) {
                    // 如果是 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
                    // 如果是 UploadAuth 上传方式, 需要根据 uploadInfo.videoId是否有值，调用点播的不同接口获取uploadauth和uploadAddress
                    // 如果 uploadInfo.videoId 有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口
                    // 注意: 这里是测试 demo 所以直接调用了获取 UploadAuth 的测试接口, 用户在使用时需要判断 uploadInfo.videoId 存在与否从而调用 openApi
                    // 如果 uploadInfo.videoId 存在, 调用 刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)
                    // 如果 uploadInfo.videoId 不存在,调用 获取视频上传地址和凭证接口(https://help.aliyun.com/document_detail/55407.html)
                    if (!uploadInfo.videoId) {
                        self.$http.post('/upload/createUploadVideo', self.params).then(data => {
                            let uploadAuth = data.uploadAuth
                            let uploadAddress = data.uploadAddress
                            let videoId = data.videoId
                            uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId)
                            // console.log("createUploadVideo:", data)
                            // console.log("onUploadStarted:", uploadInfo)
                        });

                    } else {
                        // 如果videoId有值，根据videoId刷新上传凭证

                        self.$http.post('/upload/refresheUploadVideo', { video_id: uploadInfo.videoId }).then(data => {
                            let uploadAuth = data.uploadAuth
                            let uploadAddress = data.uploadAddress
                            let videoId = data.videoId
                            uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId)
                            console.log("refresheUploadVideo:", data)
                        });
                    }
                    self.statusText = '文件开始上传...'

                },
                // 文件上传成功
                onUploadSucceed: function (uploadInfo) {
                    // console.log("onUploadSucceed: ", uploadInfo)
                    self.statusText = '文件上传成功!'
                    self.$emit("on-upload-success", uploadInfo.videoId);
                    self.loading = false
                },
                // 文件上传失败
                onUploadFailed: function (uploadInfo, code, message) {
                    console.log("onUploadFailed: file:" + uploadInfo.file.name + ",code:" + code + ", message:" + message)
                    self.statusText = '文件上传失败!'
                    self.loading = false
                },
                // 取消文件上传
                onUploadCanceled: function (uploadInfo, code, message) {
                    // console.log("Canceled file: " + uploadInfo.file.name + ", code: " + code + ", message:" + message)
                    self.statusText = '文件已暂停上传'
                    self.loading = false
                },
                // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
                onUploadProgress: function (uploadInfo, totalSize, progress) {
                    // console.log("onUploadProgress:file:" + uploadInfo.file.name + ", fileSize:" + totalSize + ", percent:" + Math.ceil(progress * 100) + "%")
                    let progressPercent = Math.ceil(progress * 100)

                    self.statusText = '文件上传中...' + progressPercent + '%'
                },
                // 上传凭证超时
                onUploadTokenExpired: function (uploadInfo) {
                    // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
                    // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
                    // 然后调用 resumeUploadWithAuth 方法, 这里是测试接口, 所以我直接获取了 UploadAuth

                    self.$http.post('/upload/refresheUploadVideo', { video_id: uploadInfo.videoId }).then(data => {
                        let uploadAuth = data.UploadAuth
                        uploader.resumeUploadWithAuth(uploadAuth)
                        console.log('upload expired and resume upload with uploadauth ', uploadAuth)
                    });
                    self.statusText = '文件超时...'
                },
                // 全部文件上传结束
                onUploadEnd: function (uploadInfo) {
                    // console.log("onUploadEnd: uploaded all the files")
                    self.statusText = '文件上传完毕'
                    self.loading = false
                }
            })
            return uploader
        },
        error(error, file) {
            this.loading = false

            this.$Modal.error({
                title: '上传失败',
                content: error
            })
        },
        formatError(file) {
            this.$Modal.warning({
                title: '格式不支持',
                content: '只允许上传 ' + this.format.join('、') + ' 格式的视频文件'
            })
        },
        sizeError(file) {
            this.$Modal.warning({
                title: '超出文件大小限制',
                content: '文件不能大于' + (this.maxSize / 1024) + 'M'
            })
        },
        showPreview() {
            this.preview = this.currentValue
        },
        delVideo() {
            this.preview = false
            this.currentValue = ''
            this.updateValue()
        },
        updateValue() {
            this.$emit('input', this.currentValue)
        }
    },
    watch: {
        value(value) {
            this.currentValue = this.value
            this.showPreview()
        },
        currentValue(value) {
            this.showPreview()
        }
    }
}
</script>
<style scoped>
.trash-video {
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
}
</style>